import Cookies from 'js-cookie';

const SupportKey = 'supportKey';
export function getSupport() {
  return Cookies.get(SupportKey);
}

export function setSupport(isSupport) {
  return Cookies.set(SupportKey, isSupport, { expires: 3 });
}

export function setCookie(key, value, expires) {
  return Cookies.set(key, value, { expires });
}

export function getCookie(key) {
  return Cookies.get(key);
}
