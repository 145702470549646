<template>
  <div>
    <el-card class="login-form-layout">
      <el-form
        autoComplete="on"
        :model="loginForm"
        :rules="loginRules"
        ref="loginForm"
        label-position="left"
      >
        <img :src="logo_icon" class="logo-layout" />
        <h2 class="login-title color-main">Swingby Admin Tool</h2>
        <el-form-item prop="email">
          <el-input
            name="email"
            type="text"
            v-model="loginForm.email"
            autoComplete="on"
            placeholder="Email"
          >
            <span slot="prefix">
              <svg-icon icon-class="user" class="color-main"></svg-icon>
            </span>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            name="password"
            :type="pwdType"
            @keyup.enter.native="handleLogin"
            v-model="loginForm.password"
            autoComplete="on"
            placeholder="Password"
          >
            <span slot="prefix">
              <svg-icon icon-class="password" class="color-main"></svg-icon>
            </span>
            <span slot="suffix" @click="showPwd">
              <svg-icon icon-class="eye" class="color-main"></svg-icon>
            </span>
          </el-input>
        </el-form-item>
        <el-form-item class="login-button-container">
          <el-button
            class="login-button"
            type="primary"
            :loading="loading"
            @click.native.prevent="handleLogin"
          >
            Login
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <img :src="login_center_bg" class="login-center-layout" />
  </div>
</template>

<script>
import { isValidEmail } from '@/utils/validate';
import { setCookie, getCookie } from '@/utils/support';
import login_center_bg from '@/assets/images/login_center_bg.png';
import logo_icon from '@/assets/logo.png';

export default {
  name: 'login',
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!isValidEmail(value)) {
        callback(new Error('Please enter the correct email'));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      callback();
    };
    return {
      loginForm: {
        email: '',
        password: '',
      },
      loginRules: {
        email: [{ required: true, trigger: 'blur', validator: validateEmail }],
        password: [
          { required: true, trigger: 'blur', validator: validatePass },
        ],
      },
      loading: false,
      pwdType: 'password',
      login_center_bg,
      logo_icon,
      dialogVisible: false,
    };
  },
  created() {
    this.loginForm.email = getCookie('email');
    this.loginForm.password = getCookie('password');
    if (
      this.loginForm.email === undefined
      || this.loginForm.email == null
      || this.loginForm.email === ''
    ) {
      this.loginForm.email = '';
    }
    if (
      this.loginForm.password === undefined
      || this.loginForm.password == null
    ) {
      this.loginForm.password = '';
    }
  },
  methods: {
    showPwd() {
      if (this.pwdType === 'password') {
        this.pwdType = '';
      } else {
        this.pwdType = 'password';
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch('Login', this.loginForm)
            .then(() => {
              this.loading = false;
              setCookie('email', this.loginForm.email, 15);
              setCookie('password', this.loginForm.password, 15);
              this.$router.push({ path: '/' });
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";

.login-form-layout {
  position: absolute;
  left: 0;
  right: 0;
  width: 360px;
  margin: 140px auto;
  border-top: 10px solid $color-main;
}

.login-title {
  text-align: center;
}

.login-center-layout {
  background: $color-main;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin-top: 200px;
}

.logo-layout {
  height: 100px;
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}

.login-button-container {
  margin-bottom: 60px;
  margin-top: 40px;
  text-align: center;
}

.login-button {
  width: 100%;
  background-color: $color-main;
}
</style>
